import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'gatsby';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from 'reactstrap';

import Logo from '../assets/images/Blindly_Header_Logo.png';

const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header>
      <Container>
        <Navbar light expand="md" style={{ background: 'none', padding: '0.5rem 0' }}>
          <NavbarBrand
            href="/"
            className="med text-color--primary margin-sm--left"
            style={{ fontSize: '22pt'}}
          >
            <img src={Logo} alt={siteTitle} style={{margin: '-10px 8px 0 0'}}/>
            <span>{siteTitle}</span>
          </NavbarBrand>
          <NavbarToggler onClick={() => setIsOpen(!isOpen)} className="mr-2"/>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/#features" className="text-color--primary">Meet Blindly</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/#screens" className="text-color--primary">App Screens</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/#team" className="text-color--primary">Our Team</NavLink>
              </NavItem>
              <NavItem>
                <Link to="/contact" className="text-color--primary nav-link">Contact Us</Link>
              </NavItem>
              <NavItem>
                <Link to="/policy" className="text-color--primary nav-link">Policy</Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
