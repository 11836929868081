import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';

import AppStoreImg from '../assets/images/App Store Badge US Black.svg';
import PlayStoreImg from '../assets/images/Google Play Badge US.svg';
import FacebookIcon from '../assets/images/f_logo_RGB-White_58.png';
import TwitterIcon from '../assets/images/Twitter_Social_Icon_Circle_White.png';
import { APP_STORE_URL, PLAY_STORE_URL, FACEBOOK_URL, TWITTER_URL } from '../config';

const colStyle = { margin: '1em 0' };

const Footer = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <>
        <div 
            className="footer full-width"
            style={{ padding: '2rem'}}
        >
            <div style={{ 
                position: 'relative',
                display: 'contents',
            }}>
            <Container style={{
                maxWidth: '768px'
            }}>
                <Row>
                    <Col xs={12} md={3} style={colStyle}>
                        <div>
                            Blindly Inc.
                            <br/>
                            {new Date().getFullYear()}
                            <br/>
                        </div>
                    </Col>
                    {!isMobile && 
                        <Col md={1} style={colStyle}>
                            <div className="vl"/>
                        </Col>
                    }
                    <Col xs={12} md={3} style={colStyle}>
                        Connect with us on
                        <div style={{marginTop: '0.5rem'}}>
                            <a href={FACEBOOK_URL}><img src={FacebookIcon} alt="Facebook" className="footer-icon" /></a>
                            <a href={TWITTER_URL} style={{marginLeft: '1rem'}}>
                                <img src={TwitterIcon} alt="Twitter" className="footer-icon" />
                            </a>
                        </div>  
                    </Col>
                    {!isMobile && 
                        <Col md={1} style={colStyle}>
                            <div className="vl"/>
                        </Col>
                    }
                    <Col xs={12} md={3} style={colStyle}>
                        <div> 
                            <a href={APP_STORE_URL}><img src={AppStoreImg} alt="Apple App Store" /></a>
                            <div style={{margin: '.5rem 0'}} />
                            <a href={PLAY_STORE_URL}><img src={PlayStoreImg} alt="Google Play Store" /></a>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
        </div>
        </>
    )
}
export default Footer;